import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLanguage } from "../../../languageContext";
import arrowImg from "../../../images/industryassets/manufacturing/arrow.png";
import BlockRow from "./BlocksRow/BlockRow";
import FullCards from "./FullCards/FullCards";
import { Fade } from "react-reveal";
const IndustryScrollerComponent = ({
  images,
  capabilityImages,
  trainingWorkflows,
  engTechImages,
  businessLangImages,
  conventionalImages,
  trainingWorkflowsAlts,
  engTechImagesAlts,
  businessLangImagesAlts,
}) => {
  const [topMargin, setTopMargin] = useState(false);
  const [first, setFirst] = useState(false);
  const [topValue, setTopValue] = useState(0);
  const [totalTopMargin, setTotalTopMargin] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const currentLang = useLanguage();
  const [menuTitles, setMenuTitles] = useState([
    { title: "Manufacturing Engineering", link: "#manufacturing" },
    { title: "Training for Manufacturing", link: "#training" },
    { title: "Engineering Technology", link: "#technology" },
    { title: "Business & Language Services", link: "#business" },
  ]);
  const [Data, setData] = useState([
    {
      id: 1,
      title: "Goken Capabilities in Manufacturing",
      heading: "",
    },
  ]);

  const scrollToDiv = (id) => {
    try {
      const element = document.querySelector(`${id}`);
      const y =
        element.getBoundingClientRect().top +
        window.scrollY -
        totalTopMargin +
        10;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const divisionData = [];
  const [topLeft, setTopLeft] = useState(true);

  const currentLanguage = useLanguage();
  //   let topValue = 0;
  useEffect(() => {
    try {
      if (!first) {
        let menuBar = document.querySelector("#scrollspy-menu-bar");

        let navBar = document.querySelector(".navbar").offsetHeight;
        setTopValue(menuBar.offsetHeight);

        setTopMargin(navBar - 2);
        setTotalTopMargin(navBar + menuBar.offsetHeight);

        // setTopValue(scrollBottomValue+navBar);
        // setBottomValue(bottom)
        setFirst(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const calculatePercentage = (top_remaining_value, item_total_height, id) => {
    try {
      let itemSelector = document.querySelector(`#fill-div-${id}`);
      let closestParent = itemSelector.parentNode.closest("div");

      console.log(top_remaining_value, item_total_height, id);
      if (
        top_remaining_value < 0 &&
        Math.abs(top_remaining_value) < item_total_height
      ) {
        let percentage_value = Math.abs(
          (top_remaining_value / item_total_height) * 100
        );
        console.log(percentage_value);
        itemSelector.style.width = percentage_value + "%";
        // find the parent div and set the color as white
        // closestParent.style.color = "white";
        try {
          if (
            !itemSelector.classList.contains(
              `background-${currentLang ? currentLang : ""}`
            )
          ) {
            itemSelector.classList.add(
              `background-${currentLang ? currentLang : ""}`
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        itemSelector.style.width = "0%";
        try {
          closestParent.style.color = "black";

          if (
            itemSelector.classList.contains(
              `background-${currentLang ? currentLang : ""}`
            )
          ) {
            itemSelector.classList.remove(
              `background-${currentLang ? currentLang : ""}`
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleScrollEvent() {
    try {
      let menuBar = document.querySelector("#manufacturing");
      let manufact = document.querySelector("#manufacturing");
      let training = document.querySelector("#training");
      let eng_tech = document.querySelector("#technology");
      let business = document.querySelector("#business");

      let scrollTopValue = menuBar.getBoundingClientRect().top - topMargin;
      let manufacturing_height = manufact.getBoundingClientRect().height;
      let manufacturing_top =
        manufact.getBoundingClientRect().top - totalTopMargin;

      let training_height = training.getBoundingClientRect().height;
      let training_top = training.getBoundingClientRect().top - totalTopMargin;

      let eng_tech_top = eng_tech.getBoundingClientRect().top - totalTopMargin;
      let eng_tech_height = eng_tech.getBoundingClientRect().height;
      let business_top = business.getBoundingClientRect().top - totalTopMargin;
      let business_height = business.getBoundingClientRect().height;

      calculatePercentage(training_top, training_height, "training");
      calculatePercentage(
        manufacturing_top,
        manufacturing_height,
        "manufacturing"
      );
      calculatePercentage(eng_tech_top, eng_tech_height, "technology");
      calculatePercentage(business_top, business_height, "business");

      const h = document.documentElement;
      let st = "scrollTop";

      if (scrollTopValue - totalTopMargin < 0) {
        setTopLeft(false);
      } else {
        setTopLeft(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  });

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="scroll-filling-menu-bar-wrapper">
      <div
        className="menu-bar-container "
        id="scrollspy-menu-bar"
        style={{
          position: `${topLeft ? "sticky" : "fixed"}`,
          top: 0,
          zIndex: 100,
          display: `${topLeft ? "none" : "flex"}`,
          // backgroundColor: `${topLeft ? "#000000" : "#888888"}`,
          marginTop: `${
            topLeft ? "0px" : topMargin ? topMargin + "px" : "74px"
          }`,
        }}
      >
        {" "}
        <Fade>
          <div
            className={`arrow-container ${topLeft ? "" : "rotate-up"}`}
            onClick={() => {
              scrollToDiv("#manufacturing-page-top");
            }}
          >
            <img
              src={arrowImg}
              alt="Gokens manufacturing capabilities"
              id="scrollspy-arrow-image"
            />
          </div>
          {menuTitles.map((item) => {
            return (
              <div className="menu-item-wrapper" key={item.link}>
                <div
                  className="fill-color-div"
                  id={`fill-div-${item.link.substring(1)}`}
                ></div>
                <button
                  className="btn-blank semi-para uppercase-text"
                  style={{ zIndex: 4, position: "relative" }}
                  onClick={() => {
                    scrollToDiv(item.link);
                  }}
                >
                  {item.title}
                </button>
              </div>
              // <a key={item.link} href={item.link}>
              //   <div className="menu-filled-bar">{item.title}</div>
              // </a>
            );
          })}
        </Fade>
      </div>

      <div
        className="main-scrollspy-container"
        // style={{
        //   paddingTop: `${topLeft ? "0px" : `${totalTopMargin}px`}`,
        // }}
      >
        {/* <div style={{ backgroundColor: "black", paddingBottom: "50px" }}></div> */}
        <div className="scrollspy-main-wrapper" id="manufacturing">
          <div className="scrollspy-header">
            <div className="heading-container">
              <div className="big-line-wrapper">
                <div className="big-line-container"></div>
              </div>
              <div className="content-wrapper">
                <h2 className="para-text uppercase-text">
                  Goken Capabilities in Manufacturing
                </h2>
                <h3 className="bold-heading uppercase-text">
                  Manufacturing <br className="hide-tablet" /> Engineering
                </h3>
              </div>
            </div>
            <div className="image-container">
              <img src={images[0] ? images[0] : ""} alt="manufacturing-page" />
            </div>
          </div>

          <BlockRow
            images={capabilityImages}
            heading={"DIGITAL CAPABILITIES"}
            data={[
              { title: "Jig & Fixture Design" },
              { title: "Stamping & Injection Tool Design" },
              {
                title:
                  "Digital Manufacturing Simulation & Optimization (Delmia)",
              },
              { title: "Digital Work Instructions" },
              { title: "3D Printing (Leverage Goken partners)" },
            ]}
          />
          <BlockRow
            heading={"CONVENTIONAL CAPABILITIES"}
            imageStyles={{
              width: "auto",
              height: "60%",
              display: "block",
              maxHeight: "auto",
            }}
            images={conventionalImages ? conventionalImages : []}
            data={[
              {
                title: "Plant layout design",
              },
              { title: "Process & Manufacturing Engineers" },
              {
                title: "PLC Programmers",
              },
              {
                title: "CNC Programmers & Technicians",
              },
              {
                title: "Quality Engineers",
              },
            ]}
          />
        </div>
        <div className="scrollspy-main-wrapper" id="training">
          <div className="scrollspy-header">
            <div className="image-container">
              <img src={images[1] ? images[1] : ""} alt="manufacturing-page" />
            </div>
            <div className="heading-container">
              <div className="big-line-wrapper">
                <div className="big-line-container"></div>
              </div>
              <div className="content-wrapper">
                <h2 className="para-text uppercase-text">
                  Goken Capabilities in Manufacturing
                </h2>
                <h3 className="bold-heading uppercase-text">
                  Training for <br className="hide-tablet" /> Manufacturing
                </h3>
              </div>
            </div>
          </div>

          <FullCards
            heading={"<b> WORKFLOW</b> FOR TRAINING DEVELOPMENT AND DELIVERY"}
            images={trainingWorkflows}
            altTexts={trainingWorkflowsAlts}
            data={[
              {
                title: "Knowledge Map to Capture Skills",
                list: [
                  " Work with senior engineers to capture essential skills, general workflow, tooling development cycle, etc.",
                  "Create a knowledge map that lays out this information.",
                  "Identify skills and group them into beginner, intermediate, and advanced categories.",
                  "Review with stakeholders and obtain agreement.",
                ],
              },
              {
                title: "Training Plan & Course Details",
                list: [
                  "Create a training plan and course guides.",
                  "The course plan shows the objective of each course, skills to be acquired, and time required.",
                  "Develop course content and exercises based on the training plan",
                  "Review plan and content with stakeholders and obtain agreement",
                ],
              },
              {
                title: "Training Delivery",
                list: [
                  "Training is delivered through classroom sessions, workshops and/or through one on one coaching sessions as needed.",
                  "Continue to capture before and after progress on key concepts using the skills matrix",
                  "Provide feedback at the end of the training process. Update materials as needed.",
                ],
              },
            ]}
          />
        </div>

        {/* 3rd section  Engineering Technology*/}
        <div className="scrollspy-main-wrapper" id="technology">
          <div className="scrollspy-header">
            <div className="heading-container">
              <div className="big-line-wrapper">
                <div className="big-line-container"></div>
              </div>
              <div className="content-wrapper">
                <h2 className="para-text uppercase-text">
                  Goken Capabilities in Manufacturing
                </h2>
                <h3 className="bold-heading uppercase-text">
                  Engineering <br className="hide-tablet" /> Technology
                </h3>
              </div>
            </div>
            <div className="image-container">
              <img
                src={images[2] ? images[2] : ""}
                alt="Engineering Technology OP Graphic"
              />
            </div>
          </div>
          <FullCards
            heading={" <b> ENGINEERING TECHNOLOGY CAPABILITIES</b>"}
            images={engTechImages}
            altTexts={engTechImagesAlts}
            data={[
              {
                title: "Operations Management",
                list: [
                  "Process Methodology",
                  "E/M BOM Management",
                  "Work Instructions/ Visual Work Instructions",
                ],
              },

              {
                title: "AR / VR",

                list: [
                  "Digitize & simplify design verification and simulation task​.",
                  "Utilize VR and IC.IDO technology for part removal and installation simulations​.",
                  "Improves design verification & simulation time by 75%.",
                ],
              },
              {
                title: "Automation",
                list: [
                  "Templates for visual work instructions",
                  "Automation of BOM Management",
                  "Manufacturing process of Automation",
                ],
              },
            ]}
          />
        </div>
        {/* 4th Section Business and Language Capabillities */}
        <div className="scrollspy-main-wrapper" id="business">
          <div className="scrollspy-header">
            <div className="image-container">
              <img
                src={images[3] ? images[3] : ""}
                alt="Business Language Graphic"
              />
            </div>
            <div className="heading-container">
              <div className="big-line-wrapper">
                <div className="big-line-container"></div>
              </div>
              <div className="content-wrapper">
                <h2 className="para-text uppercase-text">
                  Goken Capabilities in Manufacturing
                </h2>
                <h3 className="bold-heading uppercase-text">
                  Business & <br className="hide-tablet" /> Language Services
                </h3>
              </div>
            </div>
          </div>
          <FullCards
            heading={"<b>BUSINESS & LANGUAGE SERVICES CAPABILITIES</b>"}
            images={businessLangImages}
            altTexts={businessLangImagesAlts}
            data={[
              {
                title: `Purchasing/ Procurement`,
                list: ["Technical Purchasing", "Cost Analysis"],
              },
              {
                title: "Language Services",
                list: [
                  "Document Translation (Technical & Business)",
                  "Interpretation",
                  "On demand support",
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default IndustryScrollerComponent;
