import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BlockRow = ({ images, heading, data, imageStyles }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 30000, min: 1024 },
      items: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="box-layout-wrapper">
      <div className="box-layout-title">
        <h2 className="heading text-center title uppercase-text">{heading}</h2>
      </div>
      <div className="box-layout-data w-90-wrapper desktop-wrapper">
        {data.map((block, index) => {
          return (
            <div className="box-container">
              <div className="box-contents">
                <div className="box-image-wrapper">
                  {images[index] ? (
                    <img
                      style={imageStyles}
                      src={images[index] ? images[index] : ""}
                      alt="Goken's manufacturing capabilities"
                    />
                  ) : null}
                </div>
                <div className="box-content-wrapper">
                  <p className="para-text text-center">
                    {block.title ? block.title : ""}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="box-layout-tablet-wrapper">
        <Carousel
          responsive={responsive}
          pauseOnHover={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          infinite={true}
        >
          {data.map((block, index) => {
            return (
              <div className="box-container">
                <div className="box-contents">
                  <div className="box-image-wrapper">
                    {images[index] ? (
                      <img
                        style={imageStyles}
                        src={images[index] ? images[index] : ""}
                        alt="Goken's manufacturing capabilities"
                      />
                    ) : null}
                  </div>
                  <div className="box-content-wrapper">
                    <p className="para-text text-center">
                      {block.title ? block.title : ""}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default BlockRow;
