import React from "react";
import { useLanguage } from "../../../../languageContext";

const FullCards = ({ heading, images, data, altTexts = [] }) => {
  const currentLanguage = useLanguage();
  return (
    <div className="full-width-cards-wrapper">
      <div className="cards-heading-wrapper">
        <h2
          className="heading title text-center"
          dangerouslySetInnerHTML={{ __html: heading }}
        ></h2>
      </div>
      <div className="cards-content-wrapper w-90-wrapper">
        {data.map((block, index) => {
          return (
            <div
              className={`full-width-card para-text hover-border-left-${
                currentLanguage ? currentLanguage : "en"
              }`}
            >
              <div className="full-card-image-wrapper">
                {images[index] ? (
                  <img
                    src={images[index]}
                    alt={altTexts[index] ? altTexts[index] : block.title ?? ""}
                  />
                ) : null}
              </div>
              <div className="full-card-content-wrapper">
                <h3 className="semi-title bold">{block.title}</h3>
                <ul className="para">
                  {block.list
                    ? block.list.map((list) => {
                        return <li>{list}</li>;
                      })
                    : null}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FullCards;
